import { createPopper } from '@popperjs/core'

export default {
  data () {
    return {
      placement: 'top',
      settings: {
        invalidFields: {
          titleHtml: `<b class="col-12 d-flex text-dark h5 py-2 mb-0">
            Invalid Input
          </b>`,
          size: 'sm',
          buttonSize: 'sm',
          okTitle: 'Dismiss',
          okVariant: 'dark',
          headerClass: 'py-1 px-1 d-flex justify-content-center',
          footerClass: '',
          centered: true
        },
        variants: {
          success: '',
          dark: '',
          info: '',
          warning: '',
          primary: '',
          secondary: ''
        }
      }
    }
  },
  methods: {

    swalConfirm (options = {
      title: 'Confirmation',
      html: 'Would you like to save this record?',
      confirmButtonText: 'Save',
      cancelButtonText: 'Discard',
      preConfirm: null,
      customClass: {
        confirmButton: 'btn btn-sm btn-success'
      }
    }) {
      return this.$swal.fire({
        title: options?.title || 'Confirmation',
        html: typeof options?.html === 'function' ? options?.html() : options?.html,
        confirmButtonText: options?.confirmButtonText || 'Save',
        showCancelButton: true,
        cancelButtonText: options?.cancelButtonText || 'Discard',
        showLoaderOnConfirm: true,
        allowEscapeKey: false,
        heightAuto: false,
        buttonsStyling: false,
        preConfirm: () => {
          if (typeof options?.preConfirm === 'function') {
            return options?.preConfirm()
          }
        },
        allowOutsideClick: () => !this.$swal.isLoading(),
        customClass: {
          title: 'h5 p-2 text-secondary border-bottom',
          htmlContainer: 'px-2 py-3 m-0',
          actions: 'col-12 d-flex justify-content-end p-2 border-top mx-0',
          cancelButton: 'btn btn-sm btn-outline-dark ml-1',
          confirmButton: 'btn btn-sm btn-success',
          ...options?.customClass
        },
        width: '25rem'
      })
    },

    swalConfirmWithRemarks (options = {
      title: 'Confirmation',
      html: 'Would you like to save this record?',
      confirmButtonText: 'Save',
      cancelButtonText: 'Discard',
      customClass: {
        confirmButton: 'btn btn-sm btn-success'
      },
      preConfirm: null,
      remarksRequired: true
    }) {
      return this.$swal.fire({
        title: options?.title || 'Confirmation',
        html: typeof options?.html === 'function' ? options?.html() : options?.html,
        input: 'textarea',
        inputLabel: 'Remarks',
        inputAutoFocus: true,
        inputAttributes: {
          rows: 10,
          name: 'remarks',
          autocapitalize: 'off',
          placeholder: options?.remarksRequired ? 'enter remarks here' : 'enter remarks here ( optional )'
        },
        confirmButtonText: options?.confirmButtonText || 'Save',
        showCancelButton: true,
        cancelButtonText: options?.cancelButtonText || 'Discard',
        showLoaderOnConfirm: true,
        allowEscapeKey: false,
        buttonsStyling: false,
        heightAuto: false,
        preConfirm: remarks => {
          if (typeof options?.preConfirm === 'function') {
            if (options?.remarksRequired) {
              if (remarks) {
                return options?.preConfirm(remarks)
              } else {
                // if remarks is required
                return this.$swal.showValidationMessage('The remarks field is required')
              }
            }
            return options?.preConfirm(remarks)
          }
        },
        allowOutsideClick: () => !this.$swal.isLoading(),
        customClass: {
          title: 'h5 p-2 border-bottom text-secondary',
          htmlContainer: 'px-2 py-3 m-0',
          input: 'swal-input my-1 mx-3',
          inputLabel: 'd-flex align-self-start justify-content-start font-weight-bold col-12 px-3',
          validationMessage: 'd-flex align-self-start justify-content-start bg-white px-2 my-0 text-dark',
          actions: 'col-12 d-flex justify-content-end p-2 border-top mx-0',
          cancelButton: 'btn btn-sm btn-outline-dark ml-1',
          confirmButton: 'btn btn-sm btn-success',
          ...options?.customClass
        },
        width: '25rem'
      })
    },

    swalSuccess (html = 'Record has been saved', title = 'Successful') {
      return this.$swal.fire({
        title: title,
        html: typeof html === 'function' ? html() : html,
        confirmButtonText: 'Dismiss',
        allowEscapeKey: false,
        allowOutsideClick: false,
        buttonsStyling: false,
        heightAuto: false,
        customClass: {
          title: 'h5 p-2 border-bottom text-success',
          htmlContainer: 'px-2 py-3 m-0',
          actions: 'col-12 d-flex justify-content-end p-2 border-top mx-0',
          confirmButton: 'btn btn-sm btn-success'
        },
        width: '25rem'
      })
    },

    swalInvalid (html = '<h6>Your inputs seem to be incorrect or incomplete, please check and try again.</h6>', title = 'Validation Error') {
      return this.$swal.fire({
        title: title,
        html: typeof html === 'function' ? html() : html,
        confirmButtonText: 'Dismiss',
        allowEscapeKey: false,
        allowOutsideClick: false,
        buttonsStyling: false,
        heightAuto: false,
        customClass: {
          title: 'h5 p-2 border-bottom text-danger',
          htmlContainer: 'px-2 py-3 m-0',
          actions: 'col-12 d-flex justify-content-end p-2 border-top mx-0',
          confirmButton: 'btn btn-sm btn-danger'
        },
        width: '25rem'
      })
    },

    isMobile () {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    },

    scaleOnMobile (a, b) {
      if (this.isMobile()) {
        return Number(a.mobile) - Number(b.mobile)
      }
      return Number(b.index) - Number(a.index)
    },

    tableRowClass (row, selector) {
      if (row && selector === 'row') {
        return row.last_seen ? 'table-seen-request' : 'table-unseen-request'
      }
      return ''
    },

    iconState (icon, isActive) {
      return `${icon}-${isActive ? 'fill' : 'line'}`
    },

    colorBy (status = 'Pending') {
      switch (status) {
        case 'Pending': {
          return 'secondary'
        }
        case 'Processing': {
          return 'secondary'
        }
        case 'Disapproved': {
          return 'danger'
        }
        case 'Canceled': {
          return 'danger'
        }
        default: {
          return 'success'
        }
      }
    },

    generateId (length) {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'

      let result = ''

      const charactersLength = characters.length

      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
      }

      return result
    },

    calculateDropPosition (dropDownList, component, { width }) {
      dropDownList.style.width = width
      const popper = createPopper(component.$refs.toggle, dropDownList, {
        // placement: this.placement,
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -1]
            }
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn ({ state }) {
              component.$el.classList.toggle(
                'drop-up',
                state.placement === 'top'
              )
            }
          }
        ]
      })
      return () => popper.destroy()
    }
  }
}
